.top_btns[data-v-f60e472a] {
  margin-bottom: 40px;
}
.top_btns .left_box[data-v-f60e472a] {
  float: left;
}
.top_btns .right_box[data-v-f60e472a] {
  float: right;
  text-align: right;
}
.filter-item1[data-v-f60e472a] {
  margin-right: 10px;
}
.el-table__column-filter-trigger .el-icon-arrow-down[data-v-f60e472a] {
  color: white !important;
  width: 10px;
}
.none-border .div_p[data-v-f60e472a] {
  line-height: 30px;
  border-bottom: 1px solid #eee;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.none-border .div_p[data-v-f60e472a]:last-child {
  border-bottom: none;
}
.xgmStyle[data-v-f60e472a], .ybStyle[data-v-f60e472a] {
  margin-left: 10px;
}
i[data-v-f60e472a] {
  display: inline-block;
  line-height: 13px;
  font-size: 13px;
  margin-right: 3px;
}
.item_icon[data-v-f60e472a] {
  display: inline-block;
  cursor: pointer;
}
.item_icon p[data-v-f60e472a] {
  display: inline-block;
  font-size: 14px;
  line-height: 28px;
  color: #333;
}
.sbcg[data-v-f60e472a] {
  padding: 0 !important;
}
.sbyc[data-v-f60e472a] {
  color: red !important;
  padding: 0 !important;
}